import { ManageCookieService } from '../services/manage-cookie.service';

export const capitalizeFirstLetter = (string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function flattenArray(arr: any[]) {
  const result = [];
  const flatten = (subArr: any[]) => {
    subArr.forEach((item) => {
      if (Array.isArray(item)) {
        flatten(item);
      } else {
        result.push(item);
      }
    });
  };
  flatten(arr);
  return result;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isBase64(str: string): boolean {
  // Check if the length is a multiple of 4
  if (str.length % 4 !== 0) {
    return false;
  }
  // Check if the string contains only valid base64 characters
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
  return base64Regex.test(str);
}

export function checkSupportedFileFormats(fileName: string): boolean {
  const supportedFileformats = ['.docx', '.doc', '.rtf', '.odt', '.txt'];
  return supportedFileformats.some((fileFormat) => fileName.endsWith(fileFormat));
}

export function setLangCode(langCode: string) {
  switch (langCode) {
    case 'ru':
      return '';
    case 'jp':
      return '/ja';
    default:
      return '/' + langCode;
  }
}

export const waitForGACookie = (manageCookieService: ManageCookieService): Promise<void> => {
  const MAX_CHECKS = 10;
  const INTERVAL_MS = 500;

  return new Promise((resolve) => {
    let iterations = 0;

    const checkCookie = () => {
      iterations++;

      // Grab the _ga cookie
      const gaCookie = manageCookieService.getCookie('_ga');

      if (gaCookie) {
        resolve();
      } else if (iterations < MAX_CHECKS) {
        setTimeout(checkCookie, INTERVAL_MS);
      } else {
        // _ga cookie never appeared within the allowed number of checks.
        // Resolve anyway so we can proceed (or handle differently).
        resolve();
      }
    };

    checkCookie();
  });
};
