import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RefreshPageComponent } from '../modules/internal/common/components/refresh-page/refresh-page.component';
import { LoginComponent } from '../components/login/login.component';

import { HomePageGuardService as HomePageGuard } from '../services/home-page-guard.service';
import { ErrorPageComponent } from '../modules/internal/common/components/error-page/error-page.component';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [HomePageGuard],
    loadChildren: () => import('src/app/modules/public/public.module').then((m) => m.PublicModule),
  },

  { path: 'login', component: LoginComponent },
  { path: 'pricing', loadChildren: () => import('src/app/modules/pricing/pricing.module').then((m) => m.PricingModule) },
  { path: 'pricing-plans', loadChildren: () => import('src/app/modules/pricing/pricing.module').then((m) => m.PricingModule) },
  {
    path: 'cloud-storage-plans',
    loadChildren: () => import('src/app/modules/cloud-storage-plans/cloud-storage-plans.module').then((m) => m.CloudStoragePlansModule),
  },
  { path: 'checkout', loadChildren: () => import('src/app/modules/checkout/checkout.module').then((m) => m.CheckoutModule) },
  { path: 'activate', loadChildren: () => import('src/app/modules/activation/activation.module').then((m) => m.ActivationModule) },
  { path: 'purchase-success', loadChildren: () => import('src/app/modules/purchase/purchase.module').then((m) => m.PurchaseModule) },
  { path: 'order-error', loadChildren: () => import('src/app/modules/order-error/order-error.module').then((m) => m.OrderErrorModule) },
  { path: 'sharelink', loadChildren: () => import('src/app/modules/shared-link/share-link.module').then((m) => m.ShareLinkModule) },
  {
    path: 'shared-folder',
    loadChildren: () => import('src/app/modules/shared-folder/shared-folder.module').then((m) => m.SharedFolderModule),
  },
  { path: 'updates', loadChildren: () => import('src/app/modules/update/update.module').then((m) => m.UpdateModule) },
  { path: 'survey', loadChildren: () => import('src/app/modules/survey/survey.module').then((m) => m.SurveyModule) },
  {
    path: 'privacy-policy',
    loadChildren: () => import('src/app/modules/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('src/app/modules/legal/legal.module').then((m) => m.LegalModule),
  },
  { path: 'refresh-page', component: RefreshPageComponent }, // TODO when directly access refresh page nothing happen
  { path: 'error', component: ErrorPageComponent },
  {
    path: '',
    loadChildren: () => import('src/app/modules/internal/wrapper/wrapper.module').then((m) => m.WrapperModule),
  }, // Do not put anything below that statement, wild card route (**) inside wrapper module
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      //  { enableTracing: true } // <-- debugging purposes only! - Start router tracing and print every event in the console.
      { relativeLinkResolution: 'legacy' }
    ),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
