import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { catchError, first } from 'rxjs/operators';
import { fadeRegularAnimation } from '../../../../app-animations/app-animations';
import { configs } from '../../../../configs/drive-configs';
import { ApplyActionService } from '../../../../services/apply-action.service';
import { AuthService } from '../../../../services/auth.service';
import { LanguageManagerService } from '../../../../services/language-manager.service';
import { LoginService } from '../../../../services/login.service';
import { ReportPageEventService } from '../../../../services/report-page-event.service';
import { RecaptchaEnterpriseService } from 'src/app/services/recaptcha-enterprise.service';
import { SendEmailService } from 'src/app/services/send-email.service';
import { forkJoin, of } from 'rxjs';
interface TagModel {
  value: any;
  display: string;
  error?: boolean;
  isLoading: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  animations: [fadeRegularAnimation()],
})
export class ShareLinkComponent implements OnInit {
  @Input() filesData: any;
  @Input() menuSource: any;
  @Input() modal: any;
  @Input() activeModal: string;

  @ViewChild('shLink') shareLink: ElementRef;
  @ViewChild('mailMessage') mailMessage: ElementRef;

  @ViewChild('emailEntryContainer') emailEntryContainer: ElementRef;
  @ViewChild(PerfectScrollbarDirective) customScrollDirectiveRef?: PerfectScrollbarDirective;

  shareForm: FormGroup;
  sendEmailForm: FormGroup;
  fc: any;
  loading: boolean = true;

  shareError: boolean = false;
  shareStatus: boolean;

  preventBlur: boolean = false;
  itemType: string;
  showCopiedText: boolean = false;
  copiedTimeout: any;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');

  deviceData: any = this.deviceService.getDeviceInfo();
  iOS: boolean = document.getElementsByTagName('html')[0].classList.contains('os-iOS');
  OS: string = this.iOS ? 'iOS' : this.deviceData.os;

  mailView: boolean = false;
  mailBoxFocused: boolean = false;
  emailsList: any[] = [];
  invalidEmail: boolean = false;
  sendingMail: boolean = false;
  emailTimeout: any;
  emailsLimit: number = 30;
  emailTextLimit: number = 500;
  emailFc: any;
  blurCandidate: string = '';
  emailValidationLoading: boolean;

  prevTagValue: any = '';
  hasOtherOwner: boolean = false;
  heightLimit: boolean = true;

  emailLimit: number = configs.sharelinkEmailLimit;

  constructor(
    private formBuilder: FormBuilder,
    private applyActionService: ApplyActionService,
    private deviceService: DeviceDetectorService,
    private ngxModalService: NgxSmartModalService,
    private authService: AuthService,
    private loginService: LoginService,
    private reportPageEventService: ReportPageEventService,
    private languageService: LanguageManagerService,
    private cdRef: ChangeDetectorRef,
    private recaptchaService: RecaptchaEnterpriseService,
    private sendEmailService: SendEmailService
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEventDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.keyCode === 65) {
      event.preventDefault();
      this.mailMessage.nativeElement.focus();
      this.mailMessage.nativeElement.select();
    }
  }

  ngOnInit() {
    this.itemType = this.filesData[0].dir ? 'folder' : 'file';
    this.shareStatus = this.filesData[0].publiclyShared;
    this.hasOtherOwner = this.filesData[0].account !== this.loginService.getProfileData().accountId;
    const shareAction = this.activeModal !== 'unshare';

    if (this.menuSource === 'breadcrumb' && this.hasOtherOwner) {
      this.authService
        .authRequest('files', 'details', { id: this.filesData[0] })
        .pipe(first())
        .subscribe((file) => {
          file.owner = file.ownerProfile; // MSC hack because of api property name mismatch
          this.applyActionService.manageSharableLink([file], shareAction, null);
        });
    } else {
      this.applyActionService.manageSharableLink([this.filesData[0]], shareAction, null);
    }

    this.shareForm = this.formBuilder.group({
      shLink: [this.filesData[0].shareLink],
    });
    this.fc = this.shareForm.controls;

    this.sendEmailForm = this.formBuilder.group({
      mailMessage: [],
    });
    this.emailFc = this.sendEmailForm.controls;

    this.applyActionService.actionComplete.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.action === 'share') {
        this.loading = false;

        if (data.status === 'error') {
          this.shareError = true;
        } else {
          this.filesData = [];
          this.filesData.push(data.status);
          this.shareStatus = this.filesData[0].publiclyShared;
          this.shareForm.patchValue({ shLink: this.filesData[0].shareLink || this.filesData[0].shareInfo.publicShareLink });

          // Highlight text for all but ios
          if (this.OS !== 'iOS') {
            this.highlightInput();
          } else {
            this.shareLink.nativeElement.scrollLeft = 0;
          }
        }
      }
    });

    this.ngxModalService
      .getModal('actionModal')
      .onAnyCloseEvent.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.preventBlur = true;
      });
  }

  highlightInput() {
    const triggerHighlight = () => {
      this.shareLink.nativeElement.scrollLeft = 0;
      this.shareLink.nativeElement.focus();
      this.shareLink.nativeElement.select();
    };

    if (this.shareLink.nativeElement) {
      triggerHighlight();
    } else {
      setTimeout(() => {
        this.highlightInput();
      }, 20);
    }
  }

  copyToClipboard() {
    if (this.loading || this.shareError || !this.shareStatus) {
      return;
    }

    this.highlightInput();

    if (this.OS === 'iOS') {
      const oldContentEditable = this.shareLink.nativeElement.contentEditable,
        oldReadOnly = this.shareLink.nativeElement.readOnly,
        range = document.createRange();
      this.shareLink.nativeElement.scrollLeft = 0;

      this.shareLink.nativeElement.contentEditable = true;
      this.shareLink.nativeElement.readOnly = false;
      range.selectNodeContents(this.shareLink.nativeElement);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      this.shareLink.nativeElement.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

      this.shareLink.nativeElement.contentEditable = oldContentEditable;
      this.shareLink.nativeElement.readOnly = oldReadOnly;
    }

    document.execCommand('copy');
    this.showAndHideMessage('showCopiedText', 'copiedTimeout');
  }

  showAndHideMessage(itemSwitch, itemTimeout, time = 3000) {
    this[itemSwitch] = true;

    if (this[itemSwitch]) {
      clearTimeout(this[itemTimeout]);
    }

    this[itemTimeout] = setTimeout(() => {
      this[itemSwitch] = false;
    }, time);
  }

  onBlurMethod(event) {
    // Keep Shared Link Input Focused except when exit modal or send mail view
    if (this.preventBlur || this.mailView) {
      return;
    }

    this.highlightInput();
    event.preventDefault();
    event.returnValue = false;
  }

  shareFile(value: boolean) {
    if (!value && this.hasOtherOwner) {
      // prevent 'Stop Sharing' for items with other owner
      return;
    }
    this.loading = true;
    this.shareError = false;
    this.applyActionService.manageSharableLink([this.filesData[0]], value, 'modal');
  }

  showMailView(val) {
    this.mailView = val;
    this.customScrollDirectiveRef.update();

    if (!val) {
      this.shareLink.nativeElement.focus();
      this.shareLink.nativeElement.select();
    } else {
      document.getElementById('emailsListInput').focus();
      this.mailBoxFocused = true;
    }
  }

  setMailBoxState(isFocused) {
    this.mailBoxFocused = isFocused;
  }

  onBlurEmailInput(isFocused, event) {
    this.mailBoxFocused = isFocused;
    // blurCandidat is email that is typed, but not yet added on 'SendMail' button press
    this.blurCandidate = event;
  }

  onAdding(tag: TagModel) {
    const EMAILS_C = /[\,]/;
    const EMAIL_S = /[\;]/;
    const WHITESPACE = /\s/;

    // Check if email on blur event has been added and unblock Send Email button
    if (tag.display === this.blurCandidate.trim()) {
      this.blurCandidate = '';
    }

    // Check for multiple mails and validate
    let emails = [];
    const trimmed = tag.value.trim();

    if (EMAILS_C.test(tag.value) || EMAIL_S.test(tag.value) || WHITESPACE.test(tag.value)) {
      emails = trimmed
        .split(/;|,|\s+/)
        .filter((email: string) => email.length)
        .map((email: string) => email.trim());
    } else if (trimmed.length) {
      emails.push(tag.value);
    }

    if (emails.length) {
      let allowedEmails = [];

      if (emails.length + this.emailsList.length <= this.emailsLimit) {
        allowedEmails = emails;
      } else {
        const emailsLeft = this.emailsLimit - (this.emailsList.length - 1);
        allowedEmails = emails.slice(0, emailsLeft);
      }

      this.emailsList.pop();
      this.validateEmails(allowedEmails);
    }

    // Update Custom Scroll
    if (this.customScrollDirectiveRef) {
      setTimeout(() => {
        if (this.emailEntryContainer.nativeElement.clientHeight > 83) {
          this.heightLimit = false;
          this.customScrollDirectiveRef.update();
          this.customScrollDirectiveRef.scrollToBottom();
        } else {
          this.heightLimit = true;
        }
      }, 7);
    }
  }

  validateEmails(allowedEmails: any[]) {
    this.emailValidationLoading = true;
    const validationObservables = allowedEmails.map((email) => {
      return this.sendEmailService.isEmailValid(email, 'Share file').pipe(
        catchError((err) => {
          return of(err);
        })
      );
    });
    const initialListLength = this.emailsList.length;

    allowedEmails.forEach((email) => {
      if (!this.emailsList.length || !this.emailsList.some((e) => e.display === email)) {
        this.emailsList.push({ display: email, value: email, isLoading: true });
      }
    });

    forkJoin(validationObservables).subscribe(
      (isValid) => {
        this.emailValidationLoading = false;
        if (
          isValid.indexOf(false) !== -1 ||
          (typeof isValid === 'object' && isValid?.some((item) => item.retryAfter !== undefined)) ||
          isValid.some((item) => item.toString().includes('error'))
        ) {
          this.invalidEmail = true;
        }

        this.emailsList = this.emailsList.slice(0, initialListLength).concat(
          this.emailsList.slice(initialListLength).map((email, index) => {
            let setError = false;
            if ((typeof isValid === 'object' && isValid[index]?.retryAfter !== undefined) || isValid[index]?.toString().includes('error')) {
              setError = true;
            }

            return {
              display: email.display,
              value: email.value,
              error: !isValid[index] || setError ? { errorType: setError ? 'server-error' : 'validation-error' } : undefined,
              isLoading: false,
            };
          })
        );
      },
      () => {
        this.emailValidationLoading = false;
        this.invalidEmail = true;
        this.emailsList = this.emailsList.map((email) => {
          return {
            display: email.display,
            value: email.value,
            error: true ? { errorType: 'server-error' } : undefined,
            isLoading: false,
          };
        });
      }
    );
  }

  onRemove(tag) {
    // Loop updated model to check for errors
    this.invalidEmail = false;
    this.emailsList.forEach((item) => {
      if (tag.value !== item.value) {
        if (item.error) {
          this.invalidEmail = true;
        }
      }
    });
  }

  onTagSelect(tag) {
    this.prevTagValue = tag.display;
  }

  onTagEdited(tag, emailsList) {
    tag.isLoading = true;
    this.emailValidationLoading = true;

    this.sendEmailService.isEmailValid(tag.value, 'Share file').subscribe((isValid) => {
      tag.isLoading = false;
      this.emailValidationLoading = false;

      if (!isValid) {
        tag.error = true;
        this.invalidEmail = true;
      } else {
        tag.error = false;
        this.invalidEmail = false;
      }
    });

    this.emailsList = emailsList.map((item) => {
      if (item.display !== this.prevTagValue) {
        return item;
      } else {
        return tag;
      }
    });

    // Loop updated model to check for errors
    this.emailsList.forEach((item) => {
      if (item.error) {
        this.invalidEmail = true;
      }
    });
  }

  async sendEmail() {
    // Check if there is not added email
    if (this.blurCandidate) {
      return;
    }

    // Check for errors before send emails
    let hasError = false;
    this.emailsList.forEach((item) => {
      if (item.error) {
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    this.sendingMail = true;
    this.emailFc.mailMessage.disable();

    const emails = [];
    this.emailsList.forEach((item) => {
      emails.push(item.display);
    });

    const lang = this.languageService.getCurrentLang();

    const mailsData = {
      emails: emails,
      id: {
        account: this.filesData[0].account,
        key: this.filesData[0].key,
        parent: this.filesData[0].parent,
        name: this.filesData[0].name,
        root: this.filesData[0].root,
        bin: this.filesData[0].bin,
        dir: this.filesData[0].dir,
      },
      message: this.emailFc.mailMessage.value,
    };

    if (!mailsData.message) {
      delete mailsData.message;
    }

    this.cdRef.detectChanges();

    const token = await this.recaptchaService.execute('sharelink');

    this.recaptchaService.verify(token).subscribe(
      () => {
        this.authService
          .sendEmail(mailsData, lang.code, 'files', 'send-share-link-email')
          .pipe(untilDestroyed(this))
          .subscribe(
            (result) => {
              if (result.error) {
                this.modal.close();
                this.reportPageEventService.reportEvent('modal.sendShareLinkError', 'error');
              } else {
                this.sendEmailForm.patchValue({ mailMessage: '' });
                this.emailsList = [];
                this.modal.close();
                this.reportPageEventService.reportEvent('modal.sendShareLink', 'confirm');
              }
            },
            () => {
              this.modal.close();
              this.reportPageEventService.reportEvent('modal.sendShareLinkError', 'error');
            }
          );
      },
      () => {
        this.modal.close();
        this.reportPageEventService.reportEvent('modal.sendShareLinkError', 'error');
      }
    );
  }

  getUserName() {
    const profile = this.loginService.getProfileData();
    if (profile.hasEmail) {
      return profile.email;
    } else {
      return profile.name;
    }
  }
}
