/* Import Angular Stuff and Plugins */
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';

// Custom scrollbar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// HammerJS for touch events
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { velocity: 0.4, threshold: 20, direction: 6 }, // override default settings
    press: { time: 1000 },
    pan: {
      direction: 6,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
    tap: {
      enable: true,
    },
  };
}

import { AppGlobalErrorhandler } from './services/error-repot.service';

/* Import Modules */
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { SharedModule } from './modules/_shared/shared.module';
import { RecaptchaEnterpriseModule } from './modules/recaptcha-enterprise/recaptcha-enterprise.module';
import { RoutingModule } from './router/routing.module';

/* Import Components */
import { AppComponent } from './app.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { HeaderStaticMenuComponent } from './components/header/header-static-menu/header-static-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { LangStaticMenuComponent } from './components/header/lang-static-menu/lang-static-menu.component';
import { LogoAreaComponent } from './components/header/logo-area/logo-area.component';
import { ProfileMenuComponent } from './components/header/profile-menu/profile-menu.component';
import { SearchBarComponent } from './components/header/search-bar/search-bar.component';
import { UpgradeButtonComponent } from './components/header/upgrade-button/upgrade-button.component';
import { LoginComponent } from './components/login/login.component';

/* Import Services */
import { ActionMenuService } from './services/action-menu.service';
import { AdvanceDeviceDetectionService } from './services/advance-device-detection.service';
import { ApplyActionService } from './services/apply-action.service';
import { AuthService } from './services/auth.service';
import { BulkOperationsService } from './services/bulk-operations.service';
import { CacheService } from './services/cache.service';
import { ChangeDetectionManagerService } from './services/change-detection-manager.service';
import { DisabledItemsManagerService } from './services/disabled-items-manager.service';
import { DownloadManagerService } from './services/download-manager.service';
import { DragService } from './services/drag-service.service';
import { FilePreviewService } from './services/file-preview.service';
import { FileSortingService } from './services/file-sorting.service';
import { FileValidationService } from './services/file-validation.service';
import { GetFilesService } from './services/get-files.service';
import { HomePageGuardService } from './services/home-page-guard.service';
import { LanguageManagerService } from './services/language-manager.service';
import { LastSyncedTimeService } from './services/last-synced-time.service';
import { ListViewSwitcherService } from './services/list-view-switcher.service';
import { LocationService } from './services/location.service';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { ManageCookieService } from './services/manage-cookie.service';
import { NavigationHandlerService } from './services/navigation-handler.service';
import { OfficeSuiteSupportService } from './services/office-suite-support.service';
import { PageHelpersService } from './services/page-helpers.service';
import { PageInitService } from './services/page-init.service';
import { ProductVersionService } from './services/product-version.service';
import { ReportPageEventService } from './services/report-page-event.service';
import { ResponsiveLayoutService } from './services/responsive-layout.service';
import { ScrollItemsService } from './services/scroll-items.service';
import { SearchService } from './services/search.service';
import { SelectFilesService } from './services/select-files.service';
import { SendEmailService } from './services/send-email.service';
import { ServerDetectionService } from './services/server-detection.service';
import { StorageStatusService } from './services/storage-status.service';
import { TrackingService } from './services/tracking.service';
import { UploadDataService } from './services/upload/upload-data.service';
import { UploadManagerService } from './services/upload/upload-manager.service';
import { UploadStatusService } from './services/upload/upload-status.service';
import { UrlHandlerService } from './services/url-handler.service';
import { UserPlansDataService } from './services/user-plans-data.service';
import { UserProfileMenuService } from './services/user-profile-menu.service';
import { UserSessionService } from './services/user-session.service';

import { ModalModule } from './components/modal/modal.component';
import { TagInputModule } from './components/modal/ngx-chips';
import { translateBrowserLoaderFactory } from './helpers/translate-browser.loader';
import { RetryInterceptor } from './interceptors/retry.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CookieBannerComponent,
    LoginComponent,
    HeaderComponent,
    LogoAreaComponent,
    UpgradeButtonComponent,
    HeaderStaticMenuComponent,
    ProfileMenuComponent,
    LangStaticMenuComponent,
    SearchBarComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HammerModule,
    TransferHttpCacheModule,
    HttpClientModule,
    RoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    RecaptchaEnterpriseModule,
    TagInputModule,
    ModalModule,
    NgxSmartModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [
    AuthService,
    LoginService,
    LogoutService,
    HomePageGuardService,
    SelectFilesService,
    ActionMenuService,
    ApplyActionService,
    CacheService,
    UploadManagerService,
    UploadDataService,
    UploadStatusService,
    DragService,
    LocationService,
    LastSyncedTimeService,
    GetFilesService,
    FileSortingService,
    StorageStatusService,
    ReportPageEventService,
    DisabledItemsManagerService,
    OfficeSuiteSupportService,
    UserSessionService,
    FileValidationService,
    UserProfileMenuService,
    ResponsiveLayoutService,
    ScrollItemsService,
    LanguageManagerService,
    ManageCookieService,
    TrackingService,
    DownloadManagerService,
    BulkOperationsService,
    ListViewSwitcherService,
    FilePreviewService,
    ChangeDetectionManagerService,
    ServerDetectionService,
    PageInitService,
    PageHelpersService,
    UrlHandlerService,
    AdvanceDeviceDetectionService,
    ProductVersionService,
    NavigationHandlerService,
    SearchService,
    SendEmailService,
    UserPlansDataService,
    { provide: ErrorHandler, useClass: AppGlobalErrorhandler },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
