import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { configs } from '../../../../configs/drive-configs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ReportPageEventService } from '../../../../services/report-page-event.service';
import { LoginService } from '../../../../services/login.service';
import { TagModel } from '../../ngx-chips/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageStatusService } from '../../../../services/storage-status.service';
import { SendEmailService } from '../../../../services/send-email.service';
import { TrackingService } from '../../../../services/tracking.service';
import { RecaptchaEnterpriseService } from '../../../../services/recaptcha-enterprise.service';
import { IRefsMap } from '../../../../models/common.int';
import { ByteSizePipe } from '../../../../modules/_shared/pipes/byte-size.pipe';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  providers: [ByteSizePipe],
})
export class InviteComponent implements OnInit, OnDestroy {
  @Input() modal: any;
  @Input() menuSource: string = 'mail';

  @ViewChild('mailMessage') mailMessage: ElementRef;
  @ViewChild('emailEntryContainer') emailEntryContainer: ElementRef;
  @ViewChild(PerfectScrollbarDirective) customScrollDirectiveRef?: PerfectScrollbarDirective;

  mobileDevice: boolean = document.getElementsByTagName('html')[0].classList.contains('mobile-device');
  private refs: IRefsMap = {};
  storage: string;
  sendEmailForm: FormGroup;
  preventBlur: boolean = false;

  mailBoxFocused: boolean = false;
  emailsList: any[] = [];
  invalidEmail: boolean = false;
  sendingMail: boolean = false;
  emailsLimit: number = 30;
  emailTextLimit: number = 500;
  emailFc: { [key: string]: AbstractControl };
  blurCandidate: string = '';
  prevTagValue: string = '';
  heightLimit: boolean = true;
  emailLimit: number = configs.sharelinkEmailLimit;
  emailValidationLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private ngxModalService: NgxSmartModalService,
    private loginService: LoginService,
    private reportPageEventService: ReportPageEventService,
    private translateService: TranslateService,
    private storageStatusService: StorageStatusService,
    private sendEmailService: SendEmailService,
    private trackingService: TrackingService,
    private recaptchaService: RecaptchaEnterpriseService,
    private byteSizePipe: ByteSizePipe,
    private cdRef: ChangeDetectorRef
  ) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEventDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.keyCode === 65) {
      event.preventDefault();
      this.mailMessage.nativeElement.focus();
      this.mailMessage.nativeElement.select();
    }
  }

  ngOnInit() {
    this.storage = this.byteSizePipe.transform(this.storageStatusService.getStorageStatus().maximum);
    this.sendEmailForm = this.formBuilder.group({
      mailMessage: [this.translateService.instant('modal.inviteMessage', { value: this.storage })],
    });
    this.emailFc = this.sendEmailForm.controls;
    this.cdRef.detectChanges();
    this.ngxModalService.getModal('actionModal').onAnyCloseEvent.subscribe(() => {
      this.preventBlur = true;
    });
  }

  ngOnDestroy() {
    // Clean refs
    for (const ref in this.refs) {
      if (this.refs[ref]) {
        this.refs[ref].unsubscribe();
      }
    }
  }

  setMailBoxState(isFocused) {
    this.mailBoxFocused = isFocused;
  }

  onBlurEmailInput(isFocused, event) {
    this.mailBoxFocused = isFocused;
    // blurCandidat is email that is typed, but not yet added on 'SendMail' button press
    this.blurCandidate = event;
  }

  onAdding(tag: TagModel) {
    const EMAILS_C = /[\,]/;
    const EMAIL_S = /[\;]/;
    const WHITESPACE = /\s/;

    // Check if email on blur event has been added and unblock Send Email button
    if (tag.display === this.blurCandidate.trim()) {
      this.blurCandidate = '';
    }

    // Check for multiple mails and validate
    let emails = [];
    const trimmed = tag.value.trim();

    if (EMAILS_C.test(tag.value) || EMAIL_S.test(tag.value) || WHITESPACE.test(tag.value)) {
      emails = trimmed
        .split(/;|,|\s+/)
        .filter((email: string) => email.length)
        .map((email: string) => email.trim());
    } else if (trimmed.length) {
      emails.push(tag.value);
    }

    if (emails.length) {
      let allowedEmails = [];

      if (emails.length + this.emailsList.length <= this.emailsLimit) {
        allowedEmails = emails;
      } else {
        const emailsLeft = this.emailsLimit - (this.emailsList.length - 1);
        allowedEmails = emails.slice(0, emailsLeft);
      }

      this.emailsList.pop();
      this.validateEmails(allowedEmails);
    }

    // Update Custom Scroll
    if (this.customScrollDirectiveRef) {
      setTimeout(() => {
        if (this.emailEntryContainer.nativeElement.clientHeight > 83) {
          this.heightLimit = false;
          this.customScrollDirectiveRef.update();
          this.customScrollDirectiveRef.scrollToBottom();
        } else {
          this.heightLimit = true;
        }
      }, 7);
    }
  }

  validateEmails(allowedEmails: any[]) {
    this.emailValidationLoading = true;
    const validationObservables = allowedEmails.map((email) => {
      return this.sendEmailService.isEmailValid(email, 'Invite a friend').pipe(
        catchError((err) => {
          return of(err);
        })
      );
    });
    const initialListLength = this.emailsList.length;

    allowedEmails.forEach((email) => {
      if (!this.emailsList.length || !this.emailsList.some((e) => e.display === email)) {
        this.emailsList.push({ display: email, value: email, isLoading: true });
      }
    });

    forkJoin(validationObservables).subscribe(
      (isValid) => {
        this.emailValidationLoading = false;
        if (
          isValid.indexOf(false) !== -1 ||
          (typeof isValid === 'object' && isValid?.some((item) => item.retryAfter !== undefined)) ||
          isValid.some((item) => item.toString().includes('error'))
        ) {
          this.invalidEmail = true;
        }

        this.emailsList = this.emailsList.slice(0, initialListLength).concat(
          this.emailsList.slice(initialListLength).map((email, index) => {
            let setError = false;
            if ((typeof isValid === 'object' && isValid[index]?.retryAfter !== undefined) || isValid[index]?.toString().includes('error')) {
              setError = true;
            }

            return {
              display: email.display,
              value: email.value,
              error: !isValid[index] || setError ? { errorType: setError ? 'server-error' : 'validation-error' } : undefined,
              isLoading: false,
            };
          })
        );
      },
      () => {
        this.emailValidationLoading = false;
        this.invalidEmail = true;
        this.emailsList = this.emailsList.map((email) => {
          return {
            display: email.display,
            value: email.value,
            error: true ? { errorType: 'server-error' } : undefined,
            isLoading: false,
          };
        });
      }
    );
  }

  onRemove(tag) {
    // Loop updated model to check for errors
    this.invalidEmail = false;
    this.emailsList.forEach((item) => {
      if (tag.value !== item.value) {
        if (item.error) {
          this.invalidEmail = true;
        }
      }
    });
  }

  onTagSelect(tag) {
    this.prevTagValue = tag.display;
  }

  onTagEdited(tag, emailsList) {
    tag.isLoading = true;
    this.emailValidationLoading = true;

    this.sendEmailService.isEmailValid(tag.value, 'Invite a friend').subscribe((isValid) => {
      tag.isLoading = false;
      this.emailValidationLoading = false;

      if (!isValid) {
        tag.error = true;
        this.invalidEmail = true;
      } else {
        tag.error = false;
        this.invalidEmail = false;
      }
    });

    this.emailsList = emailsList.map((item) => {
      if (item.display !== this.prevTagValue) {
        return item;
      } else {
        return tag;
      }
    });

    // Loop updated model to check for errors
    this.emailsList.forEach((item) => {
      if (item.error) {
        this.invalidEmail = true;
      }
    });
  }

  async onSendInvite() {
    // Check if there is not added email
    if (this.blurCandidate) {
      return;
    }

    // Check for errors before send emails
    let hasError = false;
    this.emailsList.forEach((item) => {
      if (item.error) {
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    this.sendingMail = true;
    this.emailFc.mailMessage.disable();

    // getting recaptcha token
    const token = await this.recaptchaService.execute('invite_friends');

    const emails = this.emailsList.map((item) => item.display);
    const mailsData = {
      token,
      subject: this.translateService.instant('modal.inviteSubject', { name: this.getUserName() }),
      mails: emails,
      message: this.emailFc.mailMessage.value,
      category: 'Invite a Friend',
    };

    if (!mailsData.message) {
      delete mailsData.message;
    } else {
      mailsData.message = mailsData.message
        .replace(/\n/g, '<br>')
        .replace(new RegExp(this.storage, 'g'), `<strong>${this.storage}</strong>`)
        .replace(
          new RegExp('https://www.mobidrive.com/install', 'g'),
          `<a href="https://www.mobidrive.com/install">https://www.mobidrive.com/install</a>`
        );
    }

    this.cdRef.detectChanges();

    this.trackingService.trackEvent('web-app.usage', 'invite-friends', 'invite-send');
    this.sendEmailService.sendInvites(mailsData).subscribe(
      (result) => {
        if (result.status === 200) {
          this.sendEmailForm.patchValue({ mailMessage: '' });
          this.emailsList = [];
          this.modal.close();
          this.reportPageEventService.reportEvent('modal.sendInviteLink', 'confirm');
        } else {
          this.modal.close();
          this.reportPageEventService.reportEvent('modal.sendInviteLinkError', 'error');
        }
      },
      (error) => {
        this.modal.close();
        this.reportPageEventService.reportEvent('modal.sendInviteLinkError', 'error');
      }
    );
  }

  showMailView(val) {
    this.menuSource = val ? 'mail' : 'breadcrumb';
    this.customScrollDirectiveRef.update();

    if (val) {
      document.getElementById('emailsListInput').focus();
      this.mailBoxFocused = true;
      this.trackingService.trackEvent('web-app.usage', 'invite-friends', 'pop-up.invite');
    }
  }

  getUserName() {
    const profile = this.loginService.getProfileData();
    return profile.name || profile.email;
  }

  get isMailView(): boolean {
    return this.menuSource !== 'breadcrumb';
  }
}
