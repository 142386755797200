import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PageInitService } from 'src/app/services/page-init.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  constructor(private pageInit: PageInitService, private router: Router) {}

  ngOnInit(): void {
    this.pageInit.canShowDrive();
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }
}
