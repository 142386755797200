<div class="image-page-box sh-link" [perfectScrollbar]>
  <app-exceeded-banner [isStatic]="true"></app-exceeded-banner>
  <div class="content-wrap">
    <div class="image-container">
      <span class="img-thumb backup-img iconame-page-error"></span>
    </div>
  </div>
  <div class="text">
    <div class="version-error">
      <strong>{{ 'body.refreshPageTitle' | translate }}</strong>
      <p>
        <span (click)="redirectToHomePage()" [innerHTML]="'body.refreshPageInfo' | translate"></span>
      </p>
    </div>
  </div>
</div>
