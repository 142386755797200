import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { retryWhen, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  debug: boolean = false; // use this flag to attach a fake pushtkn to the requests to simulate errors
  excludeList = ['issue-xchange-code', 'sign-in-by-xchange-code'];

  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('connect.mobisystems.com/api') && !this.excludeList.some((item) => request.body?.toString().includes(item))) {
      let requestClone: HttpRequest<unknown>;
      if (this.debug) {
        requestClone = request.clone({
          setHeaders: {
            pushtkn: 'web://throw-fake-error-mobidrive',
          },
        });
      }

      return next.handle(this.debug ? requestClone : request).pipe(
        tap((event: HttpResponse<any>) => {
          if (event.body?.error && event.body?.retryAfter) {
            throw event.body;
          }
        }),
        retryWhen((errors) =>
          errors.pipe(
            switchMap((error, index) => {
              if (index < 5) {
                // Retry up to 5 times
                return timer(error.retryAfter);
              } else {
                if (!request.body?.toString().includes('validate-email-address')) {
                  this.router.navigate(['/error']);
                }
                return throwError(error.error);
              }
            })
          )
        )
      );
    } else {
      return next.handle(request);
    }
  }
}
